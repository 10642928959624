<template>
  <div>
    <b-card-group deck>
      <b-card header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <h6 class="mb-0">Danh sách tài khoản doanh nghiệp</h6>
        </template>
        <b-row>
          <b-col>
            <b-form-group label="Merchant ID">
              <b-form-input
                v-model="enterprise.id"
                placeholder=""
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Tên tài khoản">
              <b-form-input
                v-model="enterprise.name"
                placeholder=""
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-right">
            <b-button class="mr-2" variant="outline-primary" @click="listAll()"
              >Tìm kiếm
            </b-button>
          </b-col>
        </b-row>
        <hr />
        <b-table
          :items="items"
          :fields="fields"
          ref="table"
          striped
          hover
          responsive
          caption-top
        >
          <template #cell(options)="data">
            <router-link
              :to="{ path: '/account/enterprise/detail/' + data.item.id }"
              class="label label-xl label-primary label-pill label-inline mr-2"
              >Xem
            </router-link>
          </template>
        </b-table>
        <div class="mt-3">
          <b-pagination-nav
            v-model="currentPage"
            :link-gen="createLinkPage"
            :number-of-pages="paginate.totalPage"
            use-router
            first-number
            last-number
            align="right"
          ></b-pagination-nav>
        </div>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Notifications from "vue-notification";
import Common from "@/core/mixins/common";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Vue from "vue";

const AccountRepository = RepositoryFactory.get("account");
Vue.use(Notifications);
export default {
  mixins: [Common],
  data() {
    return {
      enterprise: {
        id: null,
        name: null,
      },
      fields: [
        { id: "ID tài khoản" },
        { name: "Tên tài khoản" },
        { balance: "Số dư" },
        { created_at: "Ngày tạo" },
        { options: "Tùy chọn" },
      ],
      url: this.$route,
      items: [],
      currentPage: 1,
      paginate: {
        limit: 25,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      },
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Tài khoản doanh nghiệp", route: "account" },
    ]);
  },
  methods: {
    listAll() {
      this.$bus.$emit("show-loading", true);
      let numberPage = 1;
      if (typeof this.$route.query.page != "undefined") {
        numberPage = this.$route.query.page;
      }
      let params = {
        page: numberPage,
      };
      if (this.enterprise.id != null && this.enterprise.id != "") {
        params.id = this.enterprise.id;
      }
      if (this.enterprise.name != null && this.enterprise.name != "") {
        params.name = this.enterprise.name;
      }
      AccountRepository.getListAccountEnterprise(params)
        .then((response) => {
          if (response.data.error_code) {
            this.notifyAlert("warn", response.data.message);
            this.items = [];
          } else {
            this.items = response.data.data.data;
            this.paginate.total = response.data.data.total;
            this.paginate.totalPage = response.data.data.last_page;
            this.paginate.currentPage = response.data.data.current_page;
          }
          this.$bus.$emit("show-loading", false);
        })
        .catch(() => {
          alert("Có lỗi xảy ra");
          this.$bus.$emit("show-loading", false);
        });
    },
  },
  created() {
    this.listAll();
  },
  computed: {},
  watch: {
    currentPage() {
      this.listAll();
    },
  },
};
</script>
